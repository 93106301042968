import { signOutSuccess } from "store/slices/authSlice";
import store from "../store";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "constants/AuthConstant";
import { notification } from "antd";

const unauthorizedCodes = [400, 401, 403];

// API Request interceptor
export function requestInterceptor(service) {
  service.interceptors.request.use(
    (config) => {
      const jwtToken = localStorage.getItem(ACCESS_TOKEN) || null;

      if (jwtToken) {
        config.headers["Authorization"] = "Token " + jwtToken;
      }

      return config;
    },
    (error) => {
      // Do something with request error here
      notification.error({
        message: "Error",
      });
      Promise.reject(error);
    }
  );
}

// API response interceptor
export function responseInterceptor(service) {
  service.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      let notificationParam = {
        message: "",
      };

      // Remove token and redirect
      if (unauthorizedCodes.includes(error.response.status)) {
        notificationParam.message = "Authentication Failed";
        notificationParam.description = "Please login again";
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);

        store.dispatch(signOutSuccess());
      }

      // Display Not Found message
      if (error.response.status === 404) {
        notificationParam.message = "Not Found";
        notification.error(notificationParam);
      }

      // Display custom error message
      if (error.response.status === 406) {
        notificationParam = {
          message: "Error",
          description: error.response.data.message,
          duration: 0,
        };
      }

      // Display Internal Server Error message
      if (error.response.status === 500) {
        notificationParam.message = "Internal Server Error";
        notification.error(notificationParam);
      }

      // Display Time Out message
      if (error.response.status === 508) {
        notificationParam.message = "Time Out";
        notification.error(notificationParam);
      }

      notification.error(notificationParam);

      return Promise.reject(error);
    }
  );
}
