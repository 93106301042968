import {
  DIR_LTR,
  LOCALE,
  NAV_TYPE_CHANGE,
  NAV_TYPE_SIDE,
  SIDE_NAV_LIGHT,
  SIDE_NAV_STYLE_CHANGE,
  THEME,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "Oliveex";
export const API_AUTH_URL = env.API_AUTH_URL;
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const BEVERAGE_BASE_URL = env.BEVERAGE_ENDPOINT_URL;
export const BREWERY_BASE_URL = env.BREWERY_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const WS_ENDPOINT_URL = env.WS_ENDPOINT_URL;
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/home`;
export const UNAUTHENTICATED_ENTRY = "/login";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: localStorage.getItem(SIDE_NAV_STYLE_CHANGE) || SIDE_NAV_LIGHT,
  locale: localStorage.getItem(LOCALE) || "en",
  navType: localStorage.getItem(NAV_TYPE_CHANGE) || NAV_TYPE_SIDE,
  topNavColor: "#2f3a50",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: localStorage.getItem(THEME) || "light",
  direction: DIR_LTR,
  blankLayout: false,
};
