import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "constants/AuthConstant";
import {
  COMPANY_ID,
  COMPANY_NAME,
  COMPANY_TYPE,
  FIRST_PRODUCTION_DATE,
  STAGES,
} from "constants/CompanyConstant";
import {
  LOCALE,
  NAV_TYPE_CHANGE,
  SIDE_NAV_STYLE_CHANGE,
  THEME,
} from "constants/ThemeConstant";
import {
  BI_ACCESS,
  DEPARTMENT,
  EMAIL,
  FULL_NAME,
  MEASUREMENT_UNITS,
} from "constants/UserConstant";
import UserService from "services/api/UserService";
import AuthService from "services/auth/AuthService";
import store from "store";
import {
  onHeaderNavColorChange,
  onTopNavColorChange,
  onNavTypeChange,
  onNavStyleChange,
  onLocaleChange,
  onSwitchTheme,
  toggleCollapsedNav,
  resetTheme,
} from "store/slices/themeSlice";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  access_token: localStorage.getItem(ACCESS_TOKEN) || null,
  refresh_token: localStorage.getItem(REFRESH_TOKEN) || null,
  companyType: localStorage.getItem(COMPANY_TYPE) || null,
  department: localStorage.getItem(DEPARTMENT) || null,
  biAccess: JSON.parse(localStorage.getItem(BI_ACCESS)) || null,
  stages: JSON.parse(localStorage.getItem(STAGES)) || null,
};

export const signIn = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      // Make a call to the API with the email and password
      // to get the refresh and access tokens
      const response = await AuthService.login({ email, password });

      localStorage.setItem(ACCESS_TOKEN, response.access);
      localStorage.setItem(REFRESH_TOKEN, response.refresh);

      // Make a call to the API to get the user information
      const userInfo = await UserService.getUserInfo();

      // Save user's information in local storage
      localStorage.setItem(BI_ACCESS, userInfo.company.bi_access);
      localStorage.setItem(DEPARTMENT, userInfo.department);
      localStorage.setItem(EMAIL, userInfo.email);
      localStorage.setItem(FULL_NAME, userInfo.full_name);
      localStorage.setItem(
        MEASUREMENT_UNITS,
        JSON.stringify(userInfo.preferences.measurement_units)
      );

      // Localization
      localStorage.setItem(
        LOCALE,
        userInfo.preferences.language === "Greek"
          ? "el"
          : userInfo.preferences.language === "Deutsch"
          ? "de"
          : "en"
      );
      store.dispatch(
        onLocaleChange(
          userInfo.preferences.language === "Greek"
            ? "el"
            : userInfo.preferences.language === "Deutsch"
            ? "de"
            : "en"
        )
      );

      // Theme
      const userTheme =
        userInfo.preferences.theme === "Light" ? "light" : "dark";

      // Set the theme in local storage and in the store
      localStorage.setItem(THEME, userTheme);
      store.dispatch(onSwitchTheme(userTheme));

      // Navigation layout
      store.dispatch(onHeaderNavColorChange(""));

      if (userInfo.preferences.navigation_layout === "Top") {
        store.dispatch(onTopNavColorChange("#2f3a50"));
        store.dispatch(toggleCollapsedNav(false));
        store.dispatch(onNavTypeChange("TOP"));
        localStorage.setItem(NAV_TYPE_CHANGE, "TOP");
      } else {
        store.dispatch(onNavTypeChange("SIDE"));
        localStorage.setItem(NAV_TYPE_CHANGE, "SIDE");
        store.dispatch(
          onNavStyleChange(
            userInfo.preferences.layout_theme === "Light"
              ? "SIDE_NAV_LIGHT"
              : "SIDE_NAV_DARK"
          )
        );
        localStorage.setItem(
          SIDE_NAV_STYLE_CHANGE,
          userInfo.preferences.layout_theme === "Light"
            ? "SIDE_NAV_LIGHT"
            : "SIDE_NAV_DARK"
        );
      }

      // Save company's information in local storage
      const companyInfo = userInfo.company;
      localStorage.setItem(COMPANY_ID, window.btoa(companyInfo.pk));
      localStorage.setItem(COMPANY_NAME, companyInfo.name);
      localStorage.setItem(COMPANY_TYPE, companyInfo.type);
      localStorage.setItem(
        FIRST_PRODUCTION_DATE,
        companyInfo.first_production_date
      );
      localStorage.setItem(STAGES, JSON.stringify(companyInfo.stages));

      return {
        access: response.access,
        refresh: response.refresh,
        companyType: companyInfo.type,
        department: userInfo.department,
        biAccess: userInfo.company.bi_access,
        stages: companyInfo.stages,
      };
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signOut = createAsyncThunk("auth/logout", async () => {
  store.dispatch(resetTheme());
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.access_token = action.payload.access;
      state.refresh_token = action.payload.refresh;
      state.redirect = "/";
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.access_token = null;
      state.refresh_token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.access_token = action.payload.access;
      state.refresh_token = action.payload.refresh;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.access_token = action.payload.access;
        state.refresh_token = action.payload.refresh;
        state.companyType = action.payload.companyType;
        state.department = action.payload.department;
        state.biAccess = action.payload.biAccess;
        state.stages = action.payload.stages;
        state.redirect = "/";
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.access_token = null;
        state.refresh_token = null;
        state.redirect = "/";
        localStorage.clear();
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.access_token = null;
        state.refresh_token = null;
        state.redirect = "/";
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
