import {
  call,
  put,
  take,
  fork,
  cancel,
  cancelled,
  delay,
} from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import { ACCESS_TOKEN } from "constants/AuthConstant";
import AuthService from "services/auth/AuthService";
import {
  connectWebSocket,
  disconnectWebSocket,
  newMessage,
} from "store/slices/websocketSlice";

// Create an event channel for WebSocket messages
function createSocketChannel(socket) {
  return eventChannel((emit) => {
    socket.onmessage = (event) => {
      emit(JSON.parse(event.data));
    };
    socket.onclose = () => {
      emit(END);
    };
    return () => {
      socket.onmessage = null;
    };
  });
}

// Saga to listen for WebSocket messages
function* listenForSocketMessages(socket) {
  const socketChannel = yield call(createSocketChannel, socket);
  try {
    while (true) {
      const payload = yield take(socketChannel);
      yield put(newMessage(payload));
      yield delay(10000); // Keep the WebSocket connection alive
    }
  } finally {
    if (yield cancelled()) {
      socketChannel.close();
      socket.close();
    }
  }
}

// Main saga to handle WebSocket connection
function* connectWebSocketSaga() {
  const socket = yield call(connectWebSocket);
  const task = yield fork(listenForSocketMessages, socket);

  yield take(disconnectWebSocket.type);
  yield cancel(task);
  yield put(disconnectWebSocket(socket));
}

// Saga to handle reconnection
function* reconnectWebSocket() {
  while (true) {
    try {
      yield call(connectWebSocketSaga);
    } catch (error) {
      console.error("WebSocket connection error:", error);
      yield delay(5000); // Wait before reconnecting
      try {
        const rs = yield call(AuthService.refreshToken);
        localStorage.setItem(ACCESS_TOKEN, rs.access);
      } catch (err) {
        console.error("Token refresh error:", err);
      }
    }
  }
}

export default function* websocketSagas() {
  yield fork(reconnectWebSocket);
}
