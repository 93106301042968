import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes as RouterRoutes } from "react-router-dom";
import SkeletonSpinLoading from "components/shared-components/SkeletonSpinLoading";
import { APP_PREFIX_PATH, AUTHENTICATED_ENTRY } from "configs/AppConfig";
import { getProtectedRoutes, publicRoutes } from "configs/RoutesConfig";
import AppRoute from "./AppRoute";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";

const Routes = () => {
  // State to store accessed routes
  const [accessedRoutes, setAccessedRoutes] = useState([]);

  // State to store loading status
  const [loading, setLoading] = useState(true);

  // Get user access details
  const companyType = useSelector((state) => state.auth.companyType);
  const department = useSelector((state) => state.auth.department);
  const biAccess = useSelector((state) => state.auth.biAccess);
  const stages = useSelector((state) => state.auth.stages);

  // Load protected routes based on user access details
  useEffect(() => {
    const routes = getProtectedRoutes(
      companyType,
      department,
      biAccess,
      stages
    );
    // Update the state with the routes that the user has access to
    setAccessedRoutes(routes);

    // Set loading to false
    setLoading(false);
  }, [companyType, department, biAccess, stages]);

  // Show loading spinner while routes are loading
  if (loading) {
    return <SkeletonSpinLoading active />;
  }

  // Render routes
  return (
    <RouterRoutes>
      <Route path='/' element={<ProtectedRoute />}>
        <Route
          path='/'
          element={
            <Navigate
              replace
              // Redirect to different route based on company type
              to={
                companyType === "Generic"
                  ? `${APP_PREFIX_PATH}/overview/assets/Utilities`
                  : AUTHENTICATED_ENTRY
              }
            />
          }
        />
        {accessedRoutes
          .filter((route) => route.access)
          .map((route, index) => (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          ))}
        <Route path='*' element={<Navigate to='/app/not-found' replace />} />
      </Route>
      <Route path='/' element={<PublicRoute />}>
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <AppRoute
                routeKey={route.key}
                component={route.component}
                {...route.meta}
              />
            }
          />
        ))}
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
